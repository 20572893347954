
import React from 'react';
import './App.css';
import './assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';



import Routes from './router/index'

function App() {
  return (
   <div>
    <Routes />
   </div>
    
  );
}

export default App;
